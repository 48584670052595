<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="accueil" role="tab" [routerLink]="['/accueil']">
      <ion-icon src="assets/svg/home-outline.svg"></ion-icon>
      <ion-label>Accueil</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="reservation" role="tab" [routerLink]="['/reservation']">
      <ion-icon src="assets/svg/calendar-outline.svg"></ion-icon>
      <ion-label>Réservation</ion-label>
    </ion-tab-button>

    <ion-tab-button *ngIf="isAdmin" tab="agenda" role="tab" [routerLink]="['/agenda']">
      <ion-icon src="assets/svg/calendar-outline.svg"></ion-icon>
      <ion-label>Agenda</ion-label>
      <ion-badge color="danger" *ngIf="unreadCount > 0" >
        {{ unreadCount }}
      </ion-badge>
    </ion-tab-button>

    <ion-tab-button *ngIf="isConnected" tab="notification" role="tab" [routerLink]="['/tabs/notification']">
      <ion-icon src="assets/svg/notifications-outline.svg"></ion-icon>
      <ion-label>Notification</ion-label>
      <ion-badge color="danger" *ngIf="unreadCount > 0" >
        {{ unreadCount }}
      </ion-badge>
    </ion-tab-button>

  </ion-tab-bar>
</ion-tabs>
