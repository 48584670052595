<ion-app>
  <ion-split-pane contentId="main-content" class="content-t">
    <ion-menu id="menu-custom" contentId="main-content" class="menu-custom" type="overlay">
      <ion-content>
        <ion-list id="inbox-list" role="list">
          <ion-row class="ion-align-items-center ion-margin-bottom">
            <ion-col size="2">
              <ion-img alt="Logo de 7Studio, studio photo professionnel à Champigny sur Marne" src="assets/logo7mid2.png" class="ms-2 custom-icon"></ion-img >
            </ion-col>
            <ion-col size="9" offset="1">
              <ion-list-header>7 Studio {{isAdmin ? '(Administrateur)' : ''}}</ion-list-header>
            </ion-col>
          </ion-row>

          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="/accueil" lines="none" detail="false" routerLinkActive="selected" role="listitem">
              <ion-icon aria-hidden="true" slot="start" src="assets/svg/home-outline.svg"></ion-icon>
              <ion-label>Accueil</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" *ngIf="!isLoggedIn">
            <ion-item routerDirection="root" routerLink="/login" lines="none" detail="false" routerLinkActive="selected" role="listitem">
              <ion-icon aria-hidden="true" slot="start" src="assets/svg/log-in-outline.svg"></ion-icon>
              <ion-label>Connexion</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" *ngIf="isLoggedIn">
            <ion-item routerDirection="root" routerLink="/profil" lines="none" detail="false" routerLinkActive="selected" role="listitem">
              <ion-icon aria-hidden="true" slot="start" src="assets/svg/person-circle-outline.svg"></ion-icon>
              <ion-label>Profil</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="/parametre" lines="none" detail="false" routerLinkActive="selected" role="listitem">
              <ion-icon aria-hidden="true" slot="start" src="assets/svg/person-circle-outline.svg"></ion-icon>
              <ion-label>Paramètre</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" *ngIf="isLoggedIn">
            <ion-item
              class="clickable-item"
              routerLink=""
              routerDirection="root"
              (click)="presentLogoutToast($event)"
              lines="none"
              detail="false" role="listitem">
              <ion-icon aria-hidden="true" slot="start" src="assets/svg/log-out-outline.svg"></ion-icon>
              <ion-label>Déconnexion</ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
